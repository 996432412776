import React, {useEffect, useRef, useState} from 'react';
import {css} from '@emotion/react';

export default function Timer({startedAt}: {startedAt: string}) {
  const {current: initialTime} = useRef(new Date(startedAt).valueOf());
  const [delta, setDelta] = useState(initialTime);

  useEffect(() => {
    let handle: number | null = null;

    const updateTimer = () => {
      const deltaInMs = Date.now() - initialTime;
      setDelta(deltaInMs);
      handle = window.requestAnimationFrame(updateTimer);
    };

    updateTimer();

    return () => {
      if (handle != null) {
        window.cancelAnimationFrame(handle);
      }
    };
  }, []);

  return <p css={styles.root}>{(delta / 1000).toFixed(3)}s</p>;
}

const styles = {
  root: css`
    font-size: 8rem;
    font-family: monospace;

    position: absolute;
    top: 4rem;
    left: 4rem;
  `,
};
