import React from 'react';
import {Player} from 'types/Entities';
import Heading from './Heading';
import {css} from '@emotion/react';
import * as colors from 'styles/colors';
import {Line} from 'react-chartjs-2';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale} from 'chart.js';
import 'chartjs-adapter-date-fns';
import {enAU} from 'date-fns/locale';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale);

type Props = {
  rival: Player;
  rivalStats:
    | {wins: number; losses: number; mojo: number; results: number[]; match_dates: {x: string; y: number}[]}
    | undefined;
};

export default function RivalCard({rival, rivalStats}: Props) {
  if (rivalStats === undefined || rivalStats === null)
    return <div css={styles.empty}>Gonna need to play them champ</div>;

  const data = {
    datasets: [
      {
        data: matchData(),
        borderColor: 'white',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: {locale: enAU},
          type: 'time',
          distribution: 'linear',
          time: {
            parser: 'yyyy-MM-dd',
            unit: 'day',
          },
        },
      },
    },
  };

  function matchData() {
    const points: {x: string; y: number}[] = [];
    rivalStats?.match_dates.reduce((accumulator, currentValue) => {
      const sum = accumulator + currentValue.y;
      points.push({x: currentValue.x, y: sum});
      return sum;
    }, 0);
    return points;
  }

  return (
    <div css={styles.rivalContainer}>
      <div css={styles.rivalHeader}>
        <h3 css={styles.name}>{rival.name}</h3>
        <img css={styles.image} src={rival.image_url}></img>
      </div>
      <div css={styles.rivalBody}>
        <div css={styles.statContainer}>
          <Heading level="3">Wins:</Heading>
          <p css={styles.stat}>{rivalStats.wins}</p>
        </div>
        <div css={styles.statContainer}>
          <Heading level="3">Losses:</Heading>
          <p css={styles.stat}>{rivalStats.losses}</p>
        </div>
        <div css={styles.statContainer}>
          <Heading level="3">Mojo:</Heading>
          <p css={styles.stat}>{rivalStats.mojo}</p>
        </div>
        <div css={styles.statContainer}>
          <Heading level="3">Recent matches:</Heading>
          <div css={styles.stat}>
            {rivalStats.results.slice(-6).map((result, id) => (
              <span key={id} css={[styles.cell, result === 1 ? styles.colorGreen : styles.colorRed]}>
                {result === 1 ? 'W' : 'L'}
              </span>
            ))}
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <Line options={options} data={data} />
    </div>
  );
}

const styles = {
  cell: css`
    text-align: center;
    width: 2.5rem;
    padding-top: 1rem;
  `,

  colorGreen: css`
    color: ${colors.green};
  `,

  colorRed: css`
    color: ${colors.red};
  `,

  image: css`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50% 50% 4px 4px;
    width: 14rem;
    height: auto;
  `,

  name: css`
    font-weight: bold;
    font-family: 'SuperMario';
    font-size: 6vw;
    text-align: center;
  `,

  rivalHeader: css`
    position: relative;
    font-size: 4vw;
    text-align: middle;
  `,

  statContainer: css`
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 15rem;
    margin: 1rem;
  `,

  stat: css`
    font-family: 'SuperMario';
    font-size: 5rem;
    padding: 0;
    width: 20rem;
    margin-top: 1rem;
  `,

  rivalContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  rivalBody: css`
    display: flex;
  `,

  matches: css`
    flex-direction: row;
  `,

  empty: css`
    font-family: 'SuperMario';
    font-size: 5rem;
    text-align: center;
  `,
};
