import {css} from '@emotion/react';
import React from 'react';
import * as colors from 'styles/colors';

export default function ArchiveBanner({
  name,
  retired,
  onUpdate,
}: {
  name: string;
  retired: boolean;
  onUpdate: () => void;
}) {
  function updatePlayer({retired}: {retired: boolean}) {
    fetch('/api/player/update', {
      method: 'put',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify({name, retired}),
    })
      .then(() => onUpdate())
      .catch(e => {
        console.log(e.message);
      });
  }

  return retired ? (
    <RetiredBanner name={name} onClick={() => updatePlayer({retired: false})} />
  ) : (
    <RetireBanner name={name} onClick={() => updatePlayer({retired: true})} />
  );
}

function RetireBanner({name, onClick}: {name: string; onClick: () => void}) {
  return (
    <div css={styles.root}>
      <div css={styles.text}>This player has not played in some time</div>
      <div>
        <button css={styles.retireButton} onClick={onClick}>
          Retire
        </button>
      </div>
    </div>
  );
}

function RetiredBanner({name, onClick}: {name: string; onClick: () => void}) {
  return (
    <div css={styles.root}>
      <div css={styles.text}>This player has retired</div>
      <div>
        <button css={styles.unretireButton} onClick={onClick}>
          We Back!
        </button>
      </div>
    </div>
  );
}

const styles = {
  root: css`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.6rem;
    padding: 2rem;
    display: flex;
    width: 100%;
    max-width: 76rem;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 3rem;
  `,

  text: css`
    font-size: 2rem;
    flex: 1;
  `,

  retireButton: css`
    display: block;
    padding: 1.5rem 2rem;
    width: auto;
    background: ${colors.orange};
    color: ${colors.white};
    text-align: center;
    font-size: 2rem;
    border-radius: 0.6rem;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `,

  unretireButton: css`
    display: block;
    padding: 1.5rem 2rem;
    width: auto;
    background: ${colors.green};
    color: ${colors.black};
    text-align: center;
    font-size: 2rem;
    border-radius: 0.6rem;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `,
};
