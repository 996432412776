import mushroom from './assets/MK8_Mushroom_Cup_Emblem.png';
import flower from './assets/MK8_Flower_Cup_Emblem.png';
import star from './assets/MK8_Star_Cup_Emblem.png';
import special from './assets/MK8_Special_Cup_Emblem.png';
import shell from './assets/MK8_Shell_Cup_Emblem.png';
import banana from './assets/MK8_Banana_Cup_Emblem.png';
import leaf from './assets/MK8_Leaf_Cup_Emblem.png';
import lightning from './assets/MK8_Lightning_Cup_Emblem.png';
import egg from './assets/MK8_Egg_Cup_Emblem.png';
import triforce from './assets/MK8_Triforce_Cup_Emblem.png';
import crossing from './assets/MK8_Crossing_Cup_Emblem.png';
import bell from './assets/MK8_Bell_Cup_Emblem.png';
import golden from './assets/MK8_Golden_Cup_Emblem.png';
import lucky from './assets/MK8_Lucky_Cup_Emblem.png';
import turnip from './assets/MK8_Turnip_Cup_Emblem.png';
import propeller from './assets/MK8_Propeller_Cup_Emblem.png';
import boomerang from './assets/MK8_Boomerang_Emblem.png';
import fruit from './assets/MK8_Fruit_Emblem.png';
import moon from './assets/MK8_Moon_Emblem.png';
import rock from './assets/MK8_Rock_Emblem.png';
import feather from './assets/MK8_Feather_Emblem.png';
import cherry from './assets/MK8_Cherry_Emblem.png';
import acorn from './assets/MK8_Acorn_Emblem.png';
import spiny from './assets/MK8_Spiny_Emblem.png';

export const CUPS = [
  {
    tracks: ['Mario Kart Stadium', 'Water Park', 'Sweet Sweet Canyon', 'Thwomp Ruins'],
    image: mushroom,
    subheading: 'Mushroom Cup',
  },
  {
    tracks: ['Mario Circuit', 'Toad Harbor', 'Twisted Mansion', 'Shy Guy Falls'],
    image: flower,
    subheading: 'Flower Cup',
  },
  {
    tracks: ['Sunshine Airport', 'Dolphin Shoals', 'Electrodrome', 'Mount Wario'],
    image: star,
    subheading: 'Star Cup',
  },
  {
    tracks: ['Cloudtop Cruise', 'Bone Dry Dunes', "Bowser's Castle", 'Rainbow Road'],
    image: special,
    subheading: 'Special Cup',
  },
  {
    tracks: ['Moo Moo Meadows (Wii)', 'Mario Circuit (GBA)', 'Cheep Cheep Beach (DS)', "Toad's Turnpike (N64)"],
    image: shell,
    subheading: 'Shell Cup',
  },
  {
    tracks: ['Dry Dry Desert (GameCube)', 'Donut Plains 3 (SNES)', 'Royal Raceway (N64)', 'DK Jungle (3DS)'],
    image: banana,
    subheading: 'Banana Cup',
  },
  {
    tracks: ['Wario Stadium (DS)', 'Sherbet Land (GameCube)', 'Melody Motorway (3DS)', 'Yoshi Valley (N64)'],
    image: leaf,
    subheading: 'Leaf Cup',
  },
  {
    tracks: ['Tick-Tock Clock (DS)', 'Piranha Plant Slide (3DS)', 'Grumble Volcano (Wii)', 'Rainbow Road (N64)'],
    image: lightning,
    subheading: 'Lightning Cup',
  },
  {
    tracks: ['Yoshi Circuit (GameCube)', 'Excitebike Arena', 'Dragon Driftway', 'Mute City'],
    image: egg,
    subheading: 'Egg Cup',
  },
  {
    tracks: ['Wario’s Gold Mine (Wii)', 'Rainbow Road (SNES)', 'Ice Ice Outpost', 'Hyrule Circuit'],
    image: triforce,
    subheading: 'Triforce Cup',
  },
  {
    tracks: ['Baby Park (GameCube)', 'Cheese Land (GBA)', 'Wild Woods', 'Animal Crossing'],
    image: crossing,
    subheading: 'Crossing Cup',
  },
  {
    tracks: ['Koopa City (3DS)', 'Ribbon Road (GBA)', 'Super Bell Subway', 'Big Blue'],
    image: bell,
    subheading: 'Bell Cup',
  },
  {
    tracks: ['Paris Promenade (Tour)', 'Toad Circuit (3DS)', 'Choco Mountain (N64)', 'Coconut Mall (Wii)'],
    image: golden,
    subheading: 'Golden Cup',
  },
  {
    tracks: ['Tokyo Blur (Tour)', 'Shroom Ridge (DS)', 'Sky Garden (GBA)', 'Ninja Hideaway'],
    image: lucky,
    subheading: 'Lucky Cup',
  },
  {
    tracks: ['New York Minute (Tour)', 'Mario Circuit 3 (SNES)', 'Kalimari Desert (N64)', 'Waluigi Pinball (DS)'],
    image: turnip,
    subheading: 'Turnip Cup',
  },
  {
    tracks: ['Sydney Sprint (Tour)', 'Snow Land (GBA)', 'Mushroom Gorge (Wii)', 'Sky-High Sundae'],
    image: propeller,
    subheading: 'Propeller Cup',
  },
  {
    tracks: ['London Loop (Tour)', 'Boo Lake (GBA)', 'Alpine Pass (3DS)', 'Maple Treeway (Wii)'],
    image: rock,
    subheading: 'Rock Cup',
  },
  {
    tracks: ['Berlin Byways (Tour)', 'Peach Gardens (DS)', 'Merry Mountain', 'Rainbow Road (3DS)'],
    image: boomerang,
    subheading: 'Boomerang Cup',
  },
  {
    tracks: ['Amsterdam Drift (Tour)', 'Riverside Park (GBA)', 'DK’s Snowboard Cross (Wii)', 'Yoshi’s Island'],
    image: fruit,
    subheading: 'Fruit Cup',
  },
  //The 'Mario Circuit' below will need to be changed to 'Mario Circuit (DS)' when we fix the detection bug that currently exists
  {
    tracks: ['Bangkok Rush (Tour)', '	Mario Circuit (DS)', 'Waluigi Stadium (GameCube)', 'Singapore Speedway (Tour)'],
    image: moon,
    subheading: 'Moon Cup',
  },
  {
    tracks: ['Athens Dash (Tour)', 'Daisy Cruiser (GameCube)', 'Moonview Highway (Wii)', 'Squeaky Clean Sprint'],
    image: feather,
    subheading: 'Feather Cup',
  },
  {
    tracks: ['Los Angeles Laps (Tour)', 'Sunset Wilds (GBA)', 'Koopa Cape (Wii)', 'Vancouver Velocity (Tour)'],
    image: cherry,
    subheading: 'Cherry Cup',
  },
  {
    tracks: ['Rome Avanti (Tour)', 'DK Mountain (GameCube)', 'Daisy Circuit (Wii)', 'Piranha Plant Cove'],
    image: acorn,
    subheading: 'Acorn Cup',
  },
  {
    tracks: ['Madrid Drive (Tour)', 'Rosalina’s Ice World (3DS)', 'Bowser Castle 3 (SNES)', 'Rainbow Road (Wii)'],
    image: spiny,
    subheading: 'Spiny Cup',
  },
];
