import React, {useState} from 'react';
import {css} from '@emotion/react';
import * as colors from 'styles/colors';
import type {Entities} from 'types';
import PlayerList from './PlayerList/PlayerList';
import {useQuery} from '@tanstack/react-query';
import request from 'lib/request';
import {Player} from 'types/Entities';
import RivalCard from './RivalCard';

type Props = {
  player: Player;
  rivalResults: Entities.ComplexPlayer['rival_results'];
};

export default function RivalTable({player, rivalResults}: Props) {
  const {data: players} = useQuery<Entities.Player[]>(['players'], () => {
    return request('/api/kartalytics/players');
  });
  const [rival, setRival] = useState<Player>();
  const [rivalStats, setRivalStats] = useState();

  function onSelectPlayer(selectedRival: Player) {
    request(`/api/kartalytics/players/${player.name}/h2h?rival=${selectedRival.name}`).then(results => {
      setRivalStats(results.rivalry);
      setRival(selectedRival);
    });
  }

  if (players == null) return null;

  return (
    <div css={styles.root}>
      <div css={styles.tbl}>
        {rivalResults.map((rival, id) => (
          <div key={id} css={styles.row}>
            <span css={[styles.cell, styles.cellRivalName]}>{rival.name}</span>
            {rival.results.map((result, id) => (
              <span key={id} css={[styles.cell, result === 1 ? styles.colorGreen : styles.colorRed]}>
                {result === 1 ? 'W' : 'L'}
              </span>
            ))}
          </div>
        ))}
      </div>
      <PlayerList autoFocus={false} players={players} onSelectPlayer={onSelectPlayer} limit={3} />
      {rival && <RivalCard rival={rival} rivalStats={rivalStats} />}
    </div>
  );
}

const styles = {
  root: css`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.6rem;
    padding: 3rem;
    min-width: 760px;
  `,

  tbl: css`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    font-size: 1.8em;
  `,

  row: css`
    display: flex;
  `,

  cell: css`
    text-align: center;
    width: 2.5rem;
    padding-top: 1rem;
  `,

  cellRivalName: css`
    text-align: left;
    width: 10rem;
  `,

  colorGreen: css`
    color: ${colors.green};
  `,

  colorRed: css`
    color: ${colors.red};
  `,
};
